import { PageProps } from 'gatsby';
import React from 'react';
import config from '../../modules/dynamic-config';

export default function CustomePage(props: PageProps) {
  const customPages = config.getCustomPages();
  const { name } = props.params;

  if (!customPages[name]) {
    return 'Customer page not found!';
  }

  return React.createElement(customPages[name], props);
}
